import React, { memo } from 'react'

import { Button, Icon, IconName, Text } from '..'
import { Stack } from '../../layout'
import { Card } from '../../surfaces'

export interface EmptyStateProps {
  'data-e2e'?: string
  buttonDisabled?: boolean
  buttonLabel?: string
  icon: IconName
  onClick?: () => void
  primaryText: string
  secondaryText?: string
}

const EmptyState = ({
  'data-e2e': dataE2e,
  buttonDisabled,
  buttonLabel,
  icon,
  onClick,
  primaryText,
  secondaryText,
}: EmptyStateProps) => (
  <Card data-e2e={dataE2e} spacing="medium">
    <Stack alignment="center" spacing="medium" inline fullWidth>
      <Icon name={icon} faStyle="fas" size="4x" variant="neutral" />

      <Text bold variant="secondary" size="large">
        {primaryText}
      </Text>

      {secondaryText && <Text variant="neutral">{secondaryText}</Text>}

      {onClick && (
        <Button
          data-e2e={dataE2e ? `${dataE2e}--cta` : undefined}
          disabled={buttonDisabled}
          onClick={onClick}
          variant="primary"
        >
          {buttonLabel}
        </Button>
      )}
    </Stack>
  </Card>
)

export default memo(EmptyState)
