import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { AppWrapper, PageContent } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { useCurrentMembership } from 'src/contexts/userContext'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'

import { useInitializeBuyerChat } from '../chat/chat-buyer/useInitializeBuyerChat'
import MainMenu from '../navigation/MainMenu/MainMenu'
import { useCurrentBuyerCompany, usePusherSubscriptions } from './shared/hooks'
import useInitializeBuyerDashboard from './shared/hooks/useInitializeBuyerDashboard'

const AsyncMarketplace = lazy(
  () => import('src/components/presentationals/buyer-dashboard/marketplace/Marketplace')
)

const BuyerDashboard = () => {
  const { search } = useLocation()
  const { path } = useRouteMatch()

  const currentMembership = useCurrentMembership()
  const buyerCompany = useCurrentBuyerCompany()

  usePusherSubscriptions()
  useOrganisationPusherSubscriptions()
  const { isReady, marketplaceUrl } = useInitializeBuyerDashboard()
  useInitializeBuyerChat({ contextualMembership: currentMembership })

  if (isReady) {
    return (
      <AppWrapper>
        <MainMenu />

        <PageContent>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route
                path={`${path}/marketplace/:marketplaceId(${buyerCompany?.marketplaceIds.join(
                  '|'
                )})`}
              >
                <AsyncMarketplace />
              </Route>

              <Route exact path="/:locale/:userType(buyer)">
                <Redirect to={{ pathname: marketplaceUrl, search }} />
              </Route>

              <Route
                exact
                path="/:locale/:userType(buyer)/marketplace/orders/details/:orderId"
                children={({ match }) =>
                  !!match ? (
                    <Redirect
                      to={{ pathname: `${marketplaceUrl}orders/details/${match.params.orderId}` }}
                    />
                  ) : (
                    <PageLoader />
                  )
                }
              />

              <Route>
                <ErrorBoundary>
                  <NotFound />
                </ErrorBoundary>
              </Route>
            </Switch>
          </Suspense>
        </PageContent>
      </AppWrapper>
    )
  }

  return null
}

export default BuyerDashboard
