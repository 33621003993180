import { Breakpoint } from '@procsea/design-system'

export enum TrackingType {
  FILTER = 'Filter',
  CTA = 'CTA',
  FORM_FIELD = 'FormField',
}

export enum TrackingPersona {
  BUYER = 'buyer',
  CENTRAL = 'central',
  SELLER = 'seller',
}

export enum TrackingSource {
  CREDIT_NOTE_LIST = 'credit_note_list',
  CUSTOM_CATALOG = 'custom_catalog',
  DIRECT_ORDER = 'direct_order',
  INVOICE_LIST = 'invoice_list',
  INVOICE_TO_GENERATE_LIST = 'invoice_to_generate_list',
  LINE_MANAGEMENT = 'line_management',
  LIVE_MARKET = 'live_market',
  ORDER_LIST = 'order_list',
  PRICE_SHEET_LIST = 'price_sheet_list',
  PRODUCT_MAPPING_SLIDING_PANEL = 'product_mapping_sliding_panel',
  PURCHASE_ORDER_LIST = 'purchase_order_list',
  SCHEDULER = 'scheduler',
  SELLER_LIVE_CATALOG = 'seller_live_catalog',
  STEF_DELIVERY_INFORMATION = 'stef_delivery_information',
  STOCK_MANAGEMENT = 'stock_management',
  UNKNOWN = 'unknown',
  PREPARATION_PAGE = 'preparation_page',
}

export interface TrackingConfig {
  breakpoint: Breakpoint
  persona: TrackingPersona
  source: TrackingSource
}

export type UseTrackingArgs = Pick<TrackingConfig, 'source'> &
  Partial<Pick<TrackingConfig, 'persona'>>

export interface UseTrackingResult {
  trackCta: (payload: TrackCtaArgs) => void
  trackFilter: (payload: TrackFilterArgs) => void
  trackFormField: (payload: TrackFormFieldArgs) => void
}

export type TrackFilterValue =
  | boolean
  | string
  | string[]
  | readonly string[]
  | number
  | number[]
  | readonly number[]
  | null
  | undefined

export interface TrackFilterArgs extends Partial<TrackingConfig> {
  filterName: string
  filterValue: TrackFilterValue
}

export enum CtaAction {
  CREATE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'create_customer_category_discount_exception',
  CREATE_CUSTOMER_DISCOUNT_EXCEPTION = 'create_customer_discount_exception',
  DELETE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'delete_customer_category_discount_exception',
  DELETE_CUSTOMER_DISCOUNT_EXCEPTION = 'delete_customer_discount_exception',
  DOWNLOAD_STEF_DELIVERY_DOCUMENT = 'download_stef_delivery_document',
  DOWNLOAD_STEF_PROOF_OF_DEVELIVERY = 'download_stef_proof_of_develivery',
  DISMISS_MAPPING_POPOVER = 'dismiss_mapping_popover',
  DUPLICATE_OFFER_AND_MAP_TO_CATALOG = 'duplicate_offer_and_map_to_catalog',
  DUPLICATE_OFFER_AS_DRAFT_AND_MAP_TO_CATALOG = 'duplicate_offer_as_draft_and_map_to_catalog',
  MAP_TO_CATALOG_PRODUCT = 'map_to_catalog_product',
  MAP_TO_DRAFT_CATALOG_PRODUCT = 'map_to_draft_catalog_product',
  NO_PRODUCT_MATCH_THE_OFFER = 'no_product_match_the_offer',
  OPEN_MAPPING_POPOVER = 'open_mapping_popover',
  OPEN_MAPPING_SLIDING_PANEL = 'open_mapping_sliding_panel',
  OPEN_OFFER_DUPLICATION_SLIDING_PANEL = 'open_offer_duplication_sliding_panel',
  UPDATE_CUSTOMER_CATEGORY_DISCOUNT_EXCEPTION = 'update_customer_category_discount_exception',
  UPDATE_CUSTOMER_DISCOUNT_EXCEPTION = 'update_customer_discount_exception',
  DELETE_BATCH = 'delete_batch',
  OPEN_PARCELS_MODAL = 'open_parcels_modal',
  VALIDATE_PARCELS = 'validate_parcels',
  DOWNLOAD_TRANSPORT_LABEL = 'download_transport_label',
  CANCEL_ORDER_ITEM = 'cancel_order_item',
  ADD_BATCH = 'add_batch',
  OPEN_EDIT_ORDER_ITEM_PANEL = 'open_edit_order_item_panel',
  DELETE_PARCEL = 'delete_parcel',
  ADD_PARCEL = 'add_parcel',
  SAVE_ORDER_ITEM = 'save_order_item',
  CHECK_OPEN_DOWNLOAD_MODAL = 'check_open_download_modal',
  GO_TO_EDIT_ORDER_PAGE = 'go_to_edit_order_page',
  SAVE = 'save',
  VALIDATE = 'validate',
  VALIDATE_AND_INVOICE = 'validate_and_invoice',
  DOWNLOAD_SSCC_LABEL = 'download_sscc_label',
  DOWNLOAD_DELIVERY_NOTICE = 'download_delivery_notice',
  DOWNLOAD_PROFORMA_DOCUMENT = 'download_proforma_document',
}

export interface TrackCtaArgs extends Partial<TrackingConfig> {
  action: CtaAction
  label: string | null
  [key: string]: any
}

export interface TrackFormFieldArgs extends Partial<TrackingConfig> {
  formFieldName: string
  value: any
  [key: string]: any
}

export const breakpointMapper: Record<Breakpoint, string> = {
  [Breakpoint.IS_MOBILE]: 'mobile',
  [Breakpoint.IS_TABLET]: 'tablet',
  [Breakpoint.IS_SMALL_DESKTOP]: 'small_desktop',
  [Breakpoint.IS_MEDIUM_DESKTOP]: 'medium_desktop',
  [Breakpoint.IS_LARGE_DESKTOP]: 'large_desktop',
  [Breakpoint.IS_DESKTOP]: 'desktop',
}

export const filterNameMapper: Record<string, string> = {
  q: 'search',
  q_items: 'secondary_search',
}
