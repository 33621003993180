import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AppWrapper, PageContent } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import { APPLI_URL } from 'src/constants/constants'
import { MembershipProvider } from 'src/contexts/MembershipContext'
import { useConnectedBuyerMembership, useCurrentMembership } from 'src/contexts/userContext'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'

import useDefaultMarketplaceId from '../buyer-dashboard/shared/hooks/marketplaces/useDefaultMarketplaceId'
import { useInitializeBuyerChat } from '../chat/chat-buyer/useInitializeBuyerChat'
import { useInitializeSellerChat } from '../chat/chat-seller/useInitializeSellerChat'
import MainMenu from '../navigation/MainMenu/MainMenu'

const AsyncSellerMarketplace = lazy(
  () => import('src/components/presentationals/seller-dashboard/SellerMarketplace')
)

const AsyncSellerSection = lazy(
  () => import('src/components/presentationals/seller-dashboard/SellerSection')
)

const SellerDashboard = () => {
  const currentMembership = useCurrentMembership()
  const buyerMembership = useConnectedBuyerMembership()
  const defaultBuyerMarketplaceId = useDefaultMarketplaceId()

  useOrganisationPusherSubscriptions()

  useInitializeSellerChat({ contextualMembership: currentMembership })

  useInitializeBuyerChat({ contextualMembership: buyerMembership })

  return (
    <AppWrapper>
      <MainMenu />
      <PageContent>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route
              path="/:locale/:userType(buyer)"
              children={({ location }) =>
                buyerMembership ? (
                  <Redirect
                    to={{
                      pathname: location.pathname.replace('buyer', 'seller'),
                    }}
                  />
                ) : (
                  <NotFound />
                )
              }
            />

            {buyerMembership && (
              <Route
                exact
                path="/:locale/:userType(seller|buyer)/marketplace/orders/details/:orderId"
                children={({ match }) =>
                  !!match && defaultBuyerMarketplaceId ? (
                    <Redirect
                      to={{
                        pathname: `/${match.params.locale}${APPLI_URL.SELLER.MARKETPLACE}${defaultBuyerMarketplaceId}/orders/details/${match.params.orderId}`,
                      }}
                    />
                  ) : (
                    <PageLoader />
                  )
                }
              />
            )}

            {buyerMembership && (
              <Route path="/:locale/:userType(seller)/marketplace/:marketplaceId">
                <MembershipProvider membership={buyerMembership}>
                  <AsyncSellerMarketplace />
                </MembershipProvider>
              </Route>
            )}

            <Route path="/:locale/:userType(seller)/">
              <AsyncSellerSection />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </PageContent>
    </AppWrapper>
  )
}

export default SellerDashboard
