import styled from 'styled-components'

import { ZIndex } from '../../../constants/ZIndex'
import { Width } from '../../../themes'
import { Box } from '../../layout'

export const StyledPanel = styled.div`
  background-color: ${props => props.theme.colors.white.base};
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`

export const StyledPanelBackground = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: ${ZIndex.SLIDING_PANEL};
  body {
    overflow: hidden;
  }
`

export const StyledPanelContent = styled(Box)<{ offsetContent?: Width }>`
  flex: 1;
  overflow-y: auto;
  min-height: 100%;

  ${props => props.offsetContent && `margin-bottom: ${props.offsetContent}`};
`
