import React, { ReactNode, memo } from 'react'

import { Variant } from '../../../themes/types/commons.type'
import { FontSize } from '../../../themes/types/typography.type'
import Infotip from '../../core/Infotip/Infotip'
import Text from '../../core/Text/Text'
import { StyledPrice, StyledPriceContainer } from './Price.styles'

export interface PriceProps {
  'data-e2e'?: string
  className?: string
  decimal?: number
  helpText?: ReactNode
  sign?: 'plus' | 'minus'
  size?: FontSize
  unit: string
  value: number | string | null
  variant?: Variant
}

const Price = ({
  'data-e2e': dataE2e = 'price',
  className,
  decimal,
  helpText,
  sign,
  size = 'medium',
  unit,
  value,
  variant = 'black',
}: PriceProps) => (
  <StyledPriceContainer className={className}>
    <StyledPrice size={size} data-e2e={dataE2e} variant={variant}>
      {sign === 'plus' && '+ '}
      {sign === 'minus' && '- '}
      {value === null && '-'}
      {typeof value === 'number' && decimal ? value.toFixed(decimal) : value}
    </StyledPrice>

    <Text quiet variant={variant} data-e2e="price-unit">
      {unit}
    </Text>

    {helpText && <Infotip data-e2e={`${dataE2e}--helpText`} helpText={helpText} />}
  </StyledPriceContainer>
)

export default memo(Price)
