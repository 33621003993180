import styled from 'styled-components'

import { Card } from '@procsea/design-system'

export const LandingPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: ${props => props.theme.spacings.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FormCard = styled(Card)`
  border-top: 3px solid ${props => props.theme.colors.primary.base};
`
