import styled from 'styled-components'

import { FontSize, Variant } from '../../../themes'

export const StyledPrice = styled.span<{ size: FontSize; variant: Variant }>`
  color: ${props => props.theme.colors[props.variant].base};
  font-size: ${props => props.theme.fontSizes[props.size]};
  font-weight: ${props => props.theme.fontWeights.bold};
`

export const StyledPriceContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: ${props => props.theme.spacings.xsmall};
  font-size: ${props => props.theme.fontSizes.large};
`
